export const checkIfHasVariants = (item) =>
  item.sizes && item.sizes.length>0

export const checkIfVariantsMissing = (item, selectedVariants) => {
  if (checkIfHasVariants(item)) {
    return (
      (item.sizes &&
        item.sizes.length > 0 &&
        selectedVariants &&
        selectedVariants.sizeSelected.value === "")
    );
  }
  return checkIfHasVariants(item);
};
